import { Card } from "react-bootstrap";
import styles from "./NotFound.module.css";
import { useState } from "react";
import { Helmet } from "react-helmet";


export function NotFound() {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
      };

  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title> 404 Not Found | Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/*" />
                <meta name="description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
                <meta name="keywords" content="dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara" />
        <meta name="author" content="404 Not Found | Carpathian Dentist" />
        <meta property="og:title" content=" 404 Not Found | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="facebook:title" content=" 404 Not Found | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta name="facebook:card" content="summary_large_image" />





            </Helmet>
      <main className={styles["container"]}>
        <h1>404 - Not Found</h1>
        <img src="https://i.imgur.com/e08Oqug.png" alt="404" className={styles["opps"]}/>
        <div >Opps! Aceasta pagina nu exista!</div>
        <div>
          Voiai sa cauti?
        </div>
        <div className={styles["carduri"]}>
        <Card className={styles["card"]}>
       <a href="/home" ><Card.Img variant="top" src="https://i.imgur.com/0typ66y.png" className={styles["card"]}/></a>
          <Card.Body>
            <Card.Text className={styles["cardText"]}>
              <h2>Home</h2>
             
              
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className={styles["card"]}>
       <a href="/servicii" ><Card.Img variant="top" src="https://i.imgur.com/Yct5Zzf.png" className={styles["card"]}/></a>
          <Card.Body>
            <Card.Text className={styles["cardText"]}>
              <h2>Servicii</h2>
             
              
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className={styles["card"]}>
       <a href="/preturi" ><Card.Img variant="top" src="https://i.imgur.com/cjjnWhm.png" /></a>
          <Card.Body>
            <Card.Text className={styles["cardText"]}>
              <h2>Preturi</h2>
             
              
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className={styles["card"]}>
       <a href="/contact" ><Card.Img variant="top" src="https://i.imgur.com/5k83YEa.png" /></a>
          <Card.Body>
            <Card.Text className={styles["cardText"]}>
              <h2>Contact</h2>
             
              
            </Card.Text>
          </Card.Body>
        </Card>
       
        </div>
      </main>
    </>
  );
}