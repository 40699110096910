import { Card, ListGroup } from "react-bootstrap";
import styles from "./Implantologie.module.css";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export function Implantologie(){

  
  
  
  return(
  <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Implantologie | Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/servicii/implantologie" />
                <meta name="description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
                <meta name="keywords" content="dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara, dentist bran, dentist brasov, stomatolog bran, stomatolog brasov" />
        <meta name="author" content="Carpathian Dentist" />
        <meta property="og:title" content="Implantologie | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/Rcop7CM.png" />
        <meta property="og:url" content="https://i.imgur.com/Rcop7CM.png" />
        <meta name="facebook:title" content="Implantologie | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/Rcop7CM.png" />
        <meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





            </Helmet>
  
  
  
  
  
  
  <div className={styles["sectiune"]}>
<Card style={{ width: '100rem' }} className={styles["card"]}>
   <div className={styles["images"]}>
     <Card.Img variant="top" src="https://i.imgur.com/VHpkv6t.png" className={styles["img"]}/>
     <Card.Img variant="top" src="https://i.imgur.com/Jpb8Ezs.png" className={styles["img"]}/>
     <Card.Img variant="top" src="https://i.imgur.com/bToFZMa.png" className={styles["img"]}/>
     </div>
     <Card.Body>
       <h4> <strong>Implantologia</strong></h4>
       <Card.Text className={styles["text"]}>
      <p> 
Implantologia dentară este o ramură a medicinei dentare care se ocupă cu înlocuirea dinților lipsă sau deteriorați prin implanturi dentare. Aceste implanturi sunt dispozitive de titan sau alte materiale biocompatibile care sunt inserate în osul maxilar sau mandibular pentru a servi ca rădăcini artificiale pentru dinți artificiali sau proteze dentare.</p>
       <p>Iată câteva aspecte importante ale implantologiei dentare:</p>
<ul>
<li><strong>Implanturi dentare: </strong>Acestea sunt dispozitive chirurgicale care sunt plasate în osul maxilar sau mandibular și care imită structura și funcția rădăcinii unui dinte natural. Implanturile sunt fabricate din materiale biocompatibile, cum ar fi titanul, și sunt concepute pentru a se integra solid în osul maxilar sau mandibular prin procesul de osteointegrare.</li>

<li><strong>Coroane dentare implant-suportate:</strong> După ce implantul dentar s-a integrat cu succes în osul maxilar sau mandibular, o coroană dentară personalizată este montată pe acesta pentru a înlocui dinții lipsă. Coroanele dentare implant-suportate sunt stabilizate și funcționează ca dinți naturali, oferind o funcționalitate și o estetică excelente.</li>

<li><strong>Punți dentare implant-suportate: </strong>Implanturile dentare pot fi, de asemenea, utilizați pentru a susține punți dentare, care sunt utilizate pentru a înlocui mai mulți dinți lipsă. Acestea sunt mai stabile decât punțile dentare tradiționale și nu necesită să fie ancorate de dinții naturali adiacenți.</li>

<li><strong>Proteze dentare fixe sau mobile pe implanturi:</strong> Pentru pacienții care au pierdut toți dinții, protezele dentare pot fi susținute de implanturi dentare pentru a le oferi o ancorare mai solidă și o funcționalitate îmbunătățită.</li>


</ul>
<p>Este important să consultați un medic dentist sau un specialist în implantologie dentară pentru a determina dacă sunteți un candidat potrivit pentru implanturi dentare și pentru a dezvolta un plan de tratament personalizat în funcție de nevoile și obiectivele dumneavoastră dentare.</p>

       </Card.Text>
     </Card.Body>
     
     <Card.Body className={styles["cardBody"]}>
   
     <FontAwesomeIcon icon={faBackward} /><Link to="/servicii/protetica"className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} >&nbsp;Protetica</Link>
     <Link to="/servicii/parodontologia" className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} > Parodontologie&nbsp;<FontAwesomeIcon icon={faForward} /></Link>
     </Card.Body>
   </Card>
   </div></>)}