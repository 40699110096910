
import { Helmet } from "react-helmet";
import styles from "./PoliticaConfidentialitate.module.css";

export function PoliticaConfidentialitate(){
 
return(
<>
<Helmet>
                <meta charSet="utf-8" />
                <title> Politica de confidentialitate | Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/politica-confidentialitate" />
                <meta name="description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
                <meta name="keywords" content="dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara" />
        <meta name="author" content="Politica de confidentialitate | Carpathian Dentist" />
        <meta property="og:title" content=" Politica de confidentialitate | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="facebook:title" content=" Politica de confidentialitate | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





            </Helmet>
<div className={styles["pagina"]}>
<h4>DECLARATIA PRIVIND PRELUCRAREA DATELOR CU CARACTER PERSONAL</h4>
    <p>Protectia datelor cu caracter personal in mediul online a devenit o necesitate legiferata si obligatorie. Orice operator trebuie sa informeze clientul/colaboratorul despre prelucrarea datelor cu caracter personal si sa garanteze pastrarea acestora in siguranta.</p>

    <p><strong>SC CARPATHIAN DENTIST SRL</strong> cu sediul in Moieciu de Jos, str.DN71A, inregistrata la Registrul Comertului cu numarul ..............., CUI .......... in calitate de operator va instiinteaza 
        pe aceasta cale despre prelucrarea si pastrarea datelor dumneavoastra cu 
        caracter personal transmise prin operatiuni de furnizare de servicii si produse.</p>
        <h5>CE SUNT DATELE CU CARACTER PERSONAL</h5>
        <ol>
          <li>informatii privind persoana fizica identificata/identificabila: nume, prenume, adresa, e-mail, telefon, semnatura, informatii financiare, informatii solicitate de autoritati specifice furnizarii de servicii si produse, informatii din monitorizare audio-video ca urmare a vizitei clientului intr-o locatie in care se furnizeaza serviciile <strong>SC CARPATHIAN DENTIST SRL</strong>, monitorizare audio-video in timpul serviciilor de suport tehnic, IP-ul, orice alte informatii necesare desfasurarii activitatii a <strong>SC CARPATHIAN DENTIST SRL</strong>.</li>
          <li>orice actiune (set de actiuni) precum colectarea, inregistrarea, organizarea, stocarea, adaptarea ori modificarea, extragerea, consultarea, utilizarea, dezvaluirea catre terti prin transmitere, diseminare, alaturarea ori combinarea, blocarea, arhivarea, stergerea, distrugerea care opereaza asupra datelor poate intra in sfera datelor cu caracter personal (direct sau indirect).</li>

          </ol>
          <h5>COLECTAREA INFORMATIILOR</h5>
        <ol>
         <strong> Surse de colectare a informatiilor:</strong>
<li>atunci cand se foloseste www.carpathaindentist.ro sau se folosesc aplicatii ale <strong>SC CARPATHIAN DENTIST SRL</strong>, se pot cere completari de formulare, chestionare (fara obligativitatea acceptarii) necesare furnizarii serviciilor;</li>
<li>tranzactii directe si indirecte intre <strong>SC CARPATHIAN DENTIST SRL</strong>, parteneri si utilizatori;</li>
<li>surse externe specifice institutiilor publice.</li>
</ol>
<ul><strong>Cum colectam date personale de la utilizatorii www.carpathaindentist.ro</strong>
<li>direct</li>
<li> folosind browserul utilizatorului si datele de trafic;</li>
<li>folosind cookie-uri.</li>

</ul>

<ol>
<li><strong>Colectarea directa a datelor cu caracter personal.</strong></li>

<p><strong>a.  Programarea</strong> pe www.carpathaindentist.ro poate insemna cererea de nume, prenume, adresa, email si orice alte date necesare procesului de contactare.</p>

<p>b.  In cadrul <strong>serviciilor de asistenta</strong> oferite va putem ruga sa ne oferiti numarul de telefon sau adresa de email pentru a va putea contacta in vederea solutionarii problemei dumneavoastra (la nevoie se pot cere si alte date care intra in categoria datelor cu caracter personal). 
Comunicarea in cadrul serviciilor de asistenta a www.carpathaindentist.ro poate fi inregistrata.</p>

<li> <strong>Colectarea datelor personale folosind datele de trafic</strong></li>

In timpul vizitei pe www.carpathaindentist.ro de pe orice dispozitiv furnizati informatii precum adresa dumneavoastra de IP, ora vizitei, localizarea, paginile vizitate. Serviciile externe de analiza a traficului (ex. Google Analytics) sunt folosite exclusiv de catre www.carpathaindentist.ro pentru imbunatatirea serviciilor si pentru asigurarea si mentinerea securitatii site-ului.

</ol>

<h5>IN CE SCOPURI PRELUCREAZA <strong>SC CARPATHIAN DENTIST SRL</strong> DATELE CU CARACTER PERSONAL</h5>
<p><strong>SC CARPATHIAN DENTIST SRL</strong> identifica (prin mijloacele fixe definite mai sus) potentiali utilizatori care acceseaza serviciile furnizate, monitorizeaza operatiuni in acest sens, realizeaza sondaje si/sau profiluri in cadrul activitatilor de marketing , realizeaza analize interne specifice serviciilor furnizate. <strong>SC CARPATHIAN DENTIST SRL</strong> prelucreaza date cu caracter personal si in vederea solutionarii diverselor proceduri de natura juridica care au legatura cu furnizarea de servicii. arhivarea lor facandu-se atat fizic cat si electronic. Exista institutii ale statului roman reglementate legal care pot cere rapoarte care sa cuprinda date cu caracter personal.</p>

<h5>IN CE TEMEI SE PRELUCREAZA DATELE CU CARACTER PERSONAL</h5>

    <ul>
Legea care defineste General Data Protection Regulation a fost promulgata si produce efecte si pe teritoriul Romaniei astfel:
<li>(art. 6(1) b GDPR) – se refera la colectarea datelor cu caracter personal prin intermediul formularului de comanda;</li>
<li>(art. 6 (1) c GDPR) se refera la datele obligatorii necesare emiterii de facturi;</li>
<li>(art 6 (1) a GDPR) este nevoie de cererea consimtamantului in vederea folosirii datelor cu caracter personal pentru marketing (abonarea la newsletter, cookie-uri etc.);</li>
<li>(art 6 (1) f GDPR si legea 506/2005 art 12 (2)) se refera la interesul legitim al www.carpathaindentist.ro vederea securizarii si optimizarii site-ului propriu (considerent 59 GDPR).</li>
</ul>
<p>COMUNICAREA DATELOR CU CARACTER PERSONAL ESTE OBLIGATORIE PENTRU UTILIZATOR,refuzul lor duce la imposibilitatea furnizarii de servicii. Utilizatorul are dreptul de a refuza prelucrarea datelor cu scopul de a fi folosite in marketing direct.</p>

<h5>DESTINATARII DATELOR CU CARACTER PERSONAL</h5>
<p>Am precizat mai sus scopurile in vederea carora se colecteaza datele cu caracter personal. Totodata, daca este cu adevarat necesar <strong>SC CARPATHIAN DENTIST SRL</strong> poate pune la dispozitia autoritatilor legale si partenerilor contractuali diverse date cu caracter personal (limitate) ale utilizatorilor pe care acestia din urma se obliga sa nu le foloseasca in alt scop (ex. autoritati publice, auditori, juristi, contabili, societati care fac parte din grupul <strong>SC CARPATHIAN DENTIST SRL</strong>).</p>
<p>In baza legii nr. 2016/679 (GDPR) adoptata de Parlamentul European exista posibilitatea de TRANSFER AL DATELOR CU CARACTER PERSONAL IN AFARA TARII.

Datele cu caracter personal pot fi stocate atat timp cat prevede legea, respectiv 5 ani pentru stocarea datelor contractuale si 10 ani pentru stocarea datelor financiare. Prelucrarea datelor se poate face pana la momentul in care s-a atins scopul care a vizat culegerea lor, momentul urmator implicand arhivarea sau distrugerea lor.</p>

<h5>MASURILE PRIVIND SECURITATEA PRELUCRARII DATELOR CU CARACTER PERSONAL</h5>
<ul>
<li>este mandatorie pastrarea confidentialitatii datelor motiv pentru care utilizatorii folosesc numele propriu si parola;</li>
<li>orice utilizator care acceseaza/colecteaza/modifica date cu caracter personal este inregistrat cu data, ora si operatiunea efectuata;</li>
<li>doar utilizatorii autorizati au dreptul sa imprime facturi, avize, contracte si alte acte care contin date cu caracter personal.</li>
</ul>
<ol>
Utilizatorul sau posibilul utilizator care intra in incidenta prelucrarii datelor cu caracter personal are urmatoarele drepturi:

<li>Dreptul de a fi informat despre colectarea datelor conform art. 15 si 15 GDPR;</li>
<li>  Dreptul de a avea acces la datele prelucrate – art. 15 GDPR;</li>
<li>  Dreptul de a cere modificarea sau chiar stergerea datelor – art. 17 GDPR, mai putin exceptiile prevazute de lege;</li>
<li>   Dreptul de a cere o minima prelucrare sau de a refuza prelucrarea datelor cu caracter personal – art. 18 GDPR;</li>
<li>  Dreptul de transfer al datelor catre alt operator – art. 20 GDPR</li>
<li>  Retragerea dreptului de prelucrare a datelor dupa ce initial s-a dat consimtamantul;</li>
<li>   Refuzul prelucrarii datelor in scopuri de marketing si crearea de profiluri;</li>
<li>   Atunci cand se simte nedreptatit utilizatorul are dreptul de a apela la ANSPDCP sau de a se indrepta catre instanta de judecata competenta.</li>

</ol>

<p><strong><strong>SC CARPATHIAN DENTIST SRL</strong> ISI REZERVA DREPTUL DE A ADUCE MODIFICARI ACESTEI DECLARATII CU PRIVIRE LA PRELUCRAREA DATELOR CU CARACTER PERSONAL FARA A INSTIINTA IN PREALABIL, PUBLICAND PE SITE VERSIUNEA ACTUALIZATA.</strong></p>

















</div>

</>



)

}