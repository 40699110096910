import React, { useEffect } from "react";
import {Helmet} from "react-helmet";
import Card from "react-bootstrap/Card";

import styles from "./Servicii.module.css";
import { Link  } from "react-router-dom";


export function Servicii() {

  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Servicii | Carpathian Dentist | Cabinet dentar  </title>
                <link rel="canonical" href="/servicii" />
                <meta name="description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
                <meta name="keywords" content="dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara, dentist bran, dentist brasov, stomatolog bran, stomatolog brasov" />
        <meta name="author" content="Carpathian Dentist" />
        <meta property="og:title" content="Servicii | Carpathian Dentist | Cabinet dentar Bran-Moieciu" />
        <meta property="og:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta property="og:url" content="https://i.imgur.com/gKhjL20.png" />
        <meta name="facebook:title" content="Servicii | Carpathian Dentist | Cabinet dentar Bran-Moieciu" />
        <meta name="facebook:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta name="facebook:card" content="https://i.imgur.com/gKhjL20.png" />





            </Helmet>
     <h1><strong>Servicii</strong></h1>
      <img
        src="https://i.imgur.com/CtZPRJD.png"
        alt="servicii"
        className={styles["figure"]}
      />
      <div className={styles["servicii"]}>
       <Card className={styles["card"]}>
       <Link to="/servicii/chirurgie" className={styles["linkuri"]} onClick={() => {
    window.scroll(0, 0);
  }} ><Card.Img variant="top" src="https://i.imgur.com/xDBmIlw.png" /></Link>
          <Card.Body>
            <Card.Text className={styles["cardText"]}>
              <h2>Chirurgie</h2>
             
              
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className={styles["card"]}>
        <Link to="/servicii/endodontie" className={styles["linkuri"]} onClick={() => {
    window.scroll(0, 0);
  }} ><Card.Img variant="top" src="https://i.imgur.com/H51Crdu.jpeg" /></Link>
          <Card.Body>
            <Card.Text className={styles["cardText"]}>
              <h2>Endodontie</h2>
             
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className={styles["card"]}>
        <Link to="/servicii/pedodontie" className={styles["linkuri"]} onClick={() => {
    window.scroll(0, 0);
  }} ><Card.Img variant="top" src="https://i.imgur.com/S9U1O6V.jpeg" /></Link>
          <Card.Body>
            <Card.Text className={styles["cardText"]}>
              <h2>Pedodontia</h2>
             
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className={styles["card"]}>
        <Link to="/servicii/protetica" className={styles["linkuri"]} onClick={() => {
    window.scroll(0, 0);
  }} ><Card.Img variant="top" src="https://i.imgur.com/XZRLEjb.jpeg" /></Link>
          <Card.Body>
            <Card.Text className={styles["cardText"]}>
              <h2>Protetica</h2>
            
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className={styles["card"]}>
        <Link to="/servicii/implantologie" className={styles["linkuri"]} onClick={() => {
    window.scroll(0, 0);
  }} ><Card.Img variant="top" src="https://i.imgur.com/oxOehEG.jpeg" /></Link>
          <Card.Body>
            <Card.Text className={styles["cardText"]}>
              <h2>Implantologie</h2>
            
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className={styles["card"]}>
        <Link to="/servicii/parodontologia" className={styles["linkuri"]} onClick={() => {
    window.scroll(0, 0);
  }} ><Card.Img variant="top" src="https://i.imgur.com/YDCqgRV.png" /></Link>
          <Card.Body>
            <Card.Text className={styles["cardText"]}>
              <h2>Parodontologia</h2>
            
            </Card.Text>
          </Card.Body>
        </Card>
     
      </div>
    </>
  );
}
