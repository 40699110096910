import { Card } from "react-bootstrap";
import styles from "./Pedodontie.module.css";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";



export function Pedodontie(){




    return(<>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Pedodontie | Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/servicii/pedodontie" />
                <meta name="description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
                <meta name="keywords" content="dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara, dentist brasov, dentist bran, stomatolog bran, stomatolog brasov" />
        <meta name="author" content="Carpathian Dentist" />
        <meta property="og:title" content="Pedodontie | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/Rcop7CM.png" />
        <meta property="og:url" content="https://carpathiandentist/pedodontie.ro" />
        <meta name="facebook:title" content="Pedodontie | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/Rcop7CM.png" />
        <meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





            </Helmet>
    
    
    
    
    
    
    <div className={styles["sectiune"]}>
    <Card style={{ width: '100rem' }} className={styles["card"]}>
       <div className={styles["images"]}>
         <Card.Img variant="top" src="https://i.imgur.com/9x3OwvY.png" className={styles["img"]}/>
         <Card.Img variant="top" src="https://i.imgur.com/59cqt9U.png" className={styles["img"]}/>
         <Card.Img variant="top" src="https://i.imgur.com/6Z7cWlZ.png" className={styles["img"]}/>
         </div>
         <Card.Body>
           <h4> <strong>Pedodontia</strong></h4>
           <Card.Text className={styles["text"]}>
          <p> 
Pedodontia este o ramură specializată în medicina dentară care se ocupă cu îngrijirea și tratamentul dinților copiilor și adolescenților. Este adesea denumită și "dentistică pediatrică". Pedodonții sunt medici dentiști specializați în tratarea problemelor dentare specifice copiilor, precum și în gestionarea fricii și anxietății asociate cu vizitele la dentist în rândul copiilor.</p>
           <p>În pedodontie, dentistii se concentrează pe:</p>
   <ul>
   <li><strong>Prevenție:</strong> Educarea copiilor și părinților în privința igienei orale corecte și a importanței unei alimentații sănătoase pentru sănătatea dinților.</li>
   
   <li><strong>Tratament: </strong>Îngrijirea și tratamentul afecțiunilor dentare specifice copiilor, cum ar fi cariile dentare, leziunile dentare traumatice și alte probleme dentare.</li>
   
   <li><strong>Conservarea dinților primari:</strong> Păstrarea dinților de lapte sau a dinților primari sănătoși este crucială pentru menținerea spațiilor pentru dinții permanenți și pentru dezvoltarea corectă a arcadelor dentare.</li>
   
   <li><strong>Managementul anxietății: </strong>Pedodonții sunt instruiți pentru a lucra cu copiii și adolescenții pentru a reduce frica și anxietatea asociate cu vizitele la dentist și tratamentele dentare.</li>
   
   <li><strong>Monitorizarea dezvoltării dentare:</strong> Pedodonții monitorizează dezvoltarea dentară a copiilor și adolescenților pentru a detecta și a trata precoce eventualele probleme ortodontice sau de aliniere a dinților.</li>
   
   
   </ul>
           </Card.Text>
         </Card.Body>
         
         <Card.Body className={styles["cardBody"]}>
       
         <FontAwesomeIcon icon={faBackward} /><Link to="/servicii/chirurgie"className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} >&nbsp;Chirurgie</Link>
         <Link to="/servicii/endodontie" className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} > Endodontie&nbsp;<FontAwesomeIcon icon={faForward} /></Link>
         </Card.Body>
       </Card>
       </div></>)
}